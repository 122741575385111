.wrap {
  font: 15px Arial, san-serif;
  border: 1px solid #c5c5c5e0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.wrap .inputs {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0 0;
  display: block;
  border-bottom: 2px solid #efefef;
}
.wrap .inputs:last-of-type {
  border-bottom: 0;
}
.wrap .inputs:before {
  content: '';
  position: relative;
  top: 11px;
  left: 9.5%;
  display: block;
  background-color: #efefef;
  height: 4px;
  width: 78%;
}
.wrap .inputs li {
  display: inline-block;
  width: 19%;
  text-align: center;
  vertical-align: top;
}
.wrap .inputs li input[type='radio'] {
  display: block;
  position: relative;
  top: 0;
  left: 50%;
  margin-left: -6px;
}
.wrap .inputs li label {
  margin-top: 0.3rem;
  width: 100%;
}
