input::placeholder {
  padding-left: 25px;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  text-decoration: none !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.btn:hover {
  text-decoration: none !important;
  background-color: whitesmoke;
}

.is-column {
  width: 100%;
  display: flex;
  flex-direction: column;
}
