.card__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  gap: 8px;
  margin: 20px 0;
}

.card__container__columns {
  width: 100%;
}

.globant__card__container__columns {
  width: 323px;
  margin: 0px;
}

.card__container__columns:first-of-type {
  margin: 0 8px 0 0;
}

.card__container__columns:last-of-type {
  margin: 0 0 0 8px;
}

.card__container__columns:only-of-type {
  margin: 0;
}

.slick-arrow::before {
  color: black;
}

.short_text {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link__overlay,
.link__overlay:hover {
  color: inherit;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.social_container {
  display: flex;
  // flex-direction: column;
  gap: 15px;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.social_link {
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  gap: 6px;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 12%;
  box-shadow: 4px 4px 10px rgba($color: #3f3f3f, $alpha: 0.1);
  cursor: pointer;
  background-color: #f2f2f2;
}

.talent {
  display: grid;
  grid-template-columns: 1fr;
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }

  gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: 2fr 1fr;
  }

  .card-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;

    span {
      font-size: 0.8rem;
    }
  }

  p {
    font-size: 0.9rem;
  }
}

.skills_content {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.skill_item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.basic-multi-select {
  position: relative;
  z-index: 3;
}

@media (min-width: 508px) {
  .social_link {
    font-size: 14px;
  }

  .short_text {
    width: 800px;
  }

  .social_container {
    flex-direction: row;
  }
}

@media (min-width: 700px) {
  .card__container {
    flex-direction: row;
  }
}

@media (min-width: 1100px) {
  .card {
    .title {
      font-size: 2rem;
    }
    .card-subtitle {
      font-size: 1.5rem;
    }
  }
  .short_text {
    width: 400px;
  }
}
