@import '../../scss/variables';
@import '../../scss/bootstrap-variables';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.form-group > label.required:after {
  color: red;
  content: ' *';
}

.body {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  min-height: 100vh;
}

.left {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10vh;
}

.right {
  background-color: $primary-color;
  min-height: 100%;
}

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 4rem 0;
  max-width: 350px;
}
.login h5 {
  text-align: center;
  line-height: 23px;
  display: inline-block;
  width: 450px;
}

.input-text {
  width: 100%;
  background-color: #e9f1fd;
  height: 45px;
  border: none;
  padding-left: 8px;
  &::placeholder {
    color: rgba(0, 0, 0, 0.27);
  }
  &--danger {
    border-bottom: 2px solid #ed0202;
  }
}

.password-wrapper {
  width: 100%;
  position: relative;
}

.password-eye {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

.link {
  align-self: flex-end;
}

.span {
  align-self: start;
  font-size: 12px;
  line-height: 14px;
  padding-left: 8px;
  &--error {
    color: #ff0404;
  }
  &--ok {
    color: #59c834;
  }
}

.icon-required {
  font-size: 10px;
  color: rgba(170, 4, 4, 0.7);
}

.msj {
  &--ok {
    color: #59c834;
  }
  &--error {
    color: rgba(170, 4, 4, 0.7);
  }
}
.icon {
  font-size: 24px;
  height: 28px;
  &--ok {
    color: #59c834;
  }
  &--error {
    color: rgba(170, 4, 4, 0.7);
  }
}

.button {
  background-color: $primary;
  display: inline-block;
  padding: 12px 36px;
  text-align: center;
  color: #fffdfd;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  //box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  border: none;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    transform: translateY(-3px);
    text-decoration: none;
  }
  &:active {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    transform: translateY(3px);
  }
  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &--white {
    background-color: #ffffff;
    color: #132c41;
  }
}

.alternativeLogo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 4em;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
  }
  &-logo {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &-img {
      height: 30px;
    }
  }
}
.navbar-brand {
  font-weight: bolder;
}
