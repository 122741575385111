.cardCommentary {
  overflow-y: scroll;
  height: 250px;
}

.commentContainer {
  margin-top: 0.25rem !important;
}

.comment {
  font-size: 13px;
  margin: 0;
  border-left: solid 2px black;
  padding-left: 5px;
}
.quote {
  font-size: 11px;
  margin: 0;
}

/* .test {
  border: 1px solid red;
} */
