.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 12px;
  padding: 7px 15px 7px 15px;
  border-radius: 15px;
  background-color: transparent;
  min-width: 100px;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 10px;
}

/* Links inside the dropdown */
.dropdown-content span {
  cursor: pointer;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content span:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #90d0ee2d;
}

.icon {
  font-size: 1.2rem;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .icon {
    font-size: 2rem;
  }

  .dropdown-content {
    left: auto;
    right: 10px;
  }
}
