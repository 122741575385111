input[type='number'] {
  appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.bugAlert {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}

@media (min-width: 1024px) {
  .large-padding {
    padding-left: 70px;
    padding-right: 70px;
  }
}
