.modal-dialog {
  outline: none;
}

.modal-content {
  background: #fff;
  height: 100%;
  max-height: 90vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}

.overlay {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: visible;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  // z-index: 2;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media (min-width: 768px) {
  .modal-content {
    max-width: 75vw;
  }
}
