@import '../../../scss/variables';

.navbar-app {
  width: 100%;
  padding: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  max-width: 100vw;
}

.newStyle {
  padding: 7px 15px 7px 15px;
  border-radius: 15px;
  &:hover {
    text-decoration: none;
  }
}
.noti {
  color: $primary-color;
  padding: 0;
  &:hover {
    color: $secondary-color;
    .badge {
      background: $secondary-color;
    }
  }
}

.newStylePerfil {
  color: $primary-color;
  border-radius: 12px;
  &:hover {
    color: $secondary-color;
  }
}

.selected {
  background-color: $primary-color;
  color: white;
  // padding-top: 4px;
  // padding-bottom: 4px;

  &:hover {
    color: white;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdown-menu {
  width: auto;
  padding: 0px 5px;
}

.item-menu {
  width: 100%;
  text-align: center;
  cursor: pointer;
  padding: 0.3rem;
  border-radius: 12px;
  color: #000000;

  &:hover {
    text-decoration: none;
    background-color: #c7c7fb2d;
  }
}

.item-menu-action-dropdown {
  text-align: start;
}

.item-menu-notification {
  width: 320px;
  padding: 5px 10px 10px 10px;
  border-radius: 12px;
  &:hover {
    text-decoration: none;
    background-color: #a4aeb87c;
  }
}

.logo {
  flex-grow: 1;
}

.mobile-order {
  order: 0;
}

.navbar-nav {
  gap: 4px;
}

.navbar-collapse {
  width: 100%;
  top: 95px;
  position: absolute;
  left: 0;
  z-index: 1000;
  grid-template-columns: auto 1fr;
}

@media (max-width: 767px) {
  .logo {
    flex-grow: 0;
  }
}
// @media (max-width: 1201px) {
//   .navbar-collapse {
//     z-index: 1000;
//   }
//   .mobile-order {
//     order: 1;
//   }
// }

@media (min-width: 1200px) {
  .navbar-collapse {
    z-index: 1;
  }
  .navbar-app {
    grid-template-columns: auto 1fr auto;
  }
  .navbar-collapse {
    position: relative;
    top: 0;
  }
}

@media (min-width: 767px) and (max-width: 1199px) {
  .navbar-collapse {
    width: 50%;
    left: 50%;
  }
}
