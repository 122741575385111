.words {
  overflow: hidden;
  height: 35px;
  display: inline-block;
  width: auto;
  text-align: center;
}

.words span {
  display: block;
  height: 100%;
  padding-left: 10px;
  animation: spin_words 6s infinite;
}

@keyframes spin_words {
  10% {
    transform: translateY(-112%);
  }
  25% {
    transform: translateY(-100%);
  }
  35% {
    transform: translateY(-212%);
  }
  50% {
    transform: translateY(-200%);
  }
  60% {
    transform: translateY(-312%);
  }
  75% {
    transform: translateY(-300%);
  }
  85% {
    transform: translateY(-412%);
  }
  100% {
    transform: translateY(-400%);
  }
}
