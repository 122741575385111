.table-style {
  padding-bottom: 160px;
  min-height: 50vh;
}

@media (max-width: 640px) {
  .table-style {
    padding-bottom: 140px;
  }
}
